@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}

// ==================================================
//  Body
// ==================================================
body {
  font-family: YuGothic, "Yu Gothic", Meiryo, Verdana, sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: calc(26 / 16);
  color: color.$site-write;
  min-width: 1080px;

  @include mixin.sp {
    font-size: 1.4rem;
    min-width: 375px;
  }
}

// ==================================================
//  Header
// ==================================================
.header {
  display: block;
  width: 100%;
  min-width: 1080px;
  position: absolute;
  z-index: 90;

  @include mixin.sp {
    min-width: 375px;
  }

  &_inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 12px 121px 12px 48px;

    @include mixin.sp {
      padding: 0;
    }
  }

  &_logo {
    display: block;
    width: 138px;
    margin: 0;
    position: fixed;
    z-index: 90;
    top: 68px;
    left: 65px;

    @include mixin.sp {
      width: 100%;
      top: 0;
      left: 0;
      padding: 7px 0 10px;
      background-color: color.$site-write;
    }

    a,
    img {
      display: block;
      width: 100%;
    }

    a {
      @include mixin.hoverOpacity60;

      @include mixin.sp {
        max-width: 102px;
        margin: 0 auto;
      }
    }
  }

  &_tel {
    font-size: 2.1rem;
    line-height: 1;
    color: inherit;

    @include mixin.sp {
      display: none;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 18px;
      height: auto;
      aspect-ratio: 1/1;
      background-image: url(../img/common/ico_tel.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      transform: translateY(1px);
      margin-right: 6px;
    }
  }

  &_buttons {
    @include mixin.sp {
      display: block;
      width: 100%;
      height: 49px;
      background-color: color.$white;
      position: fixed;
      z-index: 96;
      bottom: 0;
      left: 0;
    }
  }

  &_menuButton {
    display: block;
    width: 90px;
    color: color.$white;
    background-color: color.$site-main;
    position: fixed;
    z-index: 96;
    top: 0;
    right: 0;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    background-image: url(../img/common/ico_menu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 36px auto;
    @include mixin.hoverOpacity60;

    @include mixin.pc {
      height: auto;
      aspect-ratio: 1/1.011;
    }

    @include mixin.sp {
      width: calc((100% / 3) - (4px / 3));
      height: 47px;
      top: auto;
      bottom: 0;
      background-image: url(../img/common/ico_menu_sp.png);
      background-size: 79px auto;
    }

    // Javascript
    &-js {
      // Modifier
      &-active {
        background-image: url(../img/common/ico_menu_close.png);
      }
    }
  }

  &_telButtonSP {
    display: none;

    @include mixin.sp {
      display: block;
      width: calc((100% / 3) - (4px / 3));
      height: 47px;
      color: color.$white;
      background-color: color.$site-main;
      position: fixed;
      z-index: 96;
      bottom: 0;
      right: calc((100% / 3) - (4px / 3) + 2px);
      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap;
      background-image: url(../img/common/ico_tel_sp.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 88px auto;
    }
  }

  &_instagramButtonSP {
    display: none;

    @include mixin.sp {
      display: block;
      width: calc((100% / 3) - (4px / 3));
      height: 47px;
      color: color.$white;
      background-color: color.$site-main;
      position: fixed;
      z-index: 96;
      bottom: 0;
      left: 0;
      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap;
      background-image: url(../img/common/ico_instagram_sp.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 104px auto;
    }
  }

  &_menu {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 617px;
    position: fixed;
    z-index: 95;
    top: 0;
    right: 93px;
    color: color.$white;
    background-color: color.$site-main;
    padding: 90px 86px 274px 102px;

    @include mixin.sp {
      padding: 40px 30px 100px;
      right: 0;
      overflow: auto;
    }

    &::after {
      content: "";
      display: block;
      width: 94px;
      height: 100%;
      background-color: color.$white;
      position: absolute;
      top: 0;
      right: -93px;

      @include mixin.sp {
        display: none;
      }
    }

    &_navigation {
      display: block;
      width: 100%;
      padding: 0 20px 85px 20px;
      border-left: 1px solid color.$white;

      @include mixin.sp {
        padding: 0 0 40px 20px;
      }

      &_list {
        display: block;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;

        &_item {
          font-size: 2rem;
          line-height: 1;
          margin-top: 17px;

          @include mixin.sp {
            margin-top: 32px;
          }

          &:first-of-type {
            margin-top: 0;
          }

          &_inner {
            color: inherit;
            text-decoration: none;
            @include mixin.hoverOpacity60;
          }
        }
      }
    }

    &_bg {
      display: block;
      width: 100%;
      max-width: 428px;

      @include mixin.pc {
        position: absolute;
        right: 86px;
        bottom: 53px;
      }

      @include mixin.sp {
        max-width: 180px;
        margin: 60px auto 0;
      }
    }

    // Javascript
    &-js {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;

      // Modifier
      &-active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_links {
    @include mixin.sp {
      display: none;
    }

    &_item {
      display: block;
      width: 90px;
      height: auto;
      aspect-ratio: 1/1.011;
      color: color.$white;
      background-color: color.$site-main;
      position: fixed;
      z-index: 96;
      top: 0;
      right: 0;
      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 58px auto;
      @include mixin.hoverOpacity60;

      // Modifier
      &-shop {
        top: 94px;
        background-image: url(../img/common/ico_shop.png);
      }
      &-instagram {
        top: 188px;
        background-image: url(../img/common/ico_instagram.png);
      }
    }
  }
}

// ==================================================
//  Main
// ==================================================
.main {
  display: block;
  width: 100%;
  overflow: hidden;

  // Keyvisual
  &_keyvisual {
    display: block;
    width: 100%;
    padding: 46px 121px 46px 48px;

    @include mixin.sp {
      padding: 76px 20px 0;
    }

    &_inner {
      display: block;
      width: 100%;
      height: 321px;
      overflow: hidden;
      border-radius: 15px;

      @include mixin.sp {
        height: auto;
        aspect-ratio: 1/0.522;
      }
    }

    &_bg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // Group
  &_group {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1040px;
    margin: 51px auto 0;
    padding-left: 60px;

    @include mixin.sp {
      margin: 0;
      padding-left: 15px;
    }

    &_title {
      display: block;
      width: calc(78px + 74px);

      @include mixin.sp {
        width: calc(44px + 23px);
      }

      &_text {
        @include mixin.font-yumincho;
        font-size: 7.8rem;
        font-weight: 900;
        line-height: 1;
        margin: 0;
        color: color.$site-write;
        opacity: 0.15;
        position: relative;
        writing-mode: vertical-rl;

        @include mixin.sp {
          font-size: 4.4rem;
        }

        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 61px;
          background-color: color.$site-write;
          position: absolute;
          top: -71px;
          left: 12px;
          transform: row;

          @include mixin.sp {
            left: 20px;
          }
        }
      }
    }

    &_content {
      display: block;
      width: calc(100% - 78px - 74px);

      @include mixin.sp {
        width: calc(100% - 44px - 23px);
      }

      &_inner {
        display: block;
        width: 100%;
        padding: 45px 52px;
        background-color: color.$site-bg;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          display: block;
          width: 160px;
          height: auto;
          aspect-ratio: 1/0.563;
          background-image: url(../img/common/bg_symbol.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          position: absolute;
          z-index: -1;
          top: 22px;
          right: 28px;
        }
      }
    }
  }

  // Modifier
  &:not(.page-top) {
    // Group
    .main_group {
      @include mixin.sp {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 40px);
        padding: 0;
        margin: 32px auto 0;
      }

      &_title {
        @include mixin.sp {
          width: 100%;
        }

        &_text {
          @include mixin.sp {
            margin: 0 0 32px;
            writing-mode: unset;
            font-size: 4.6rem;
            text-align: center;
          }

          &::before {
            @include mixin.sp {
              display: none;
            }
          }
        }
      }

      &_content {
        @include mixin.sp {
          width: 100%;
        }
        &_inner {
          @include mixin.sp {
            padding: 40px 24px;
          }

          &::after {
          }
        }
      }
    }
  }
}

// ==================================================
//  Footer
// ==================================================
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 20px 49px;
  color: color.$white;
  background-color: color.$site-main;
  margin-top: 91px;
  position: relative;

  @include mixin.sp {
    padding: 40px 20px 79px;
    margin-top: 80px;
  }

  &_logo {
    display: block;
    width: 212px;
    @include mixin.hoverOpacity60;

    & > img {
      display: block;
      width: 100%;
    }
  }

  &_tel {
    font-size: 2.1rem;
    line-height: 1;
    color: inherit;
    margin-top: 54px;
    text-decoration: none;

    @include mixin.sp {
      margin-top: 30px;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 18px;
      height: auto;
      aspect-ratio: 1/1;
      background-image: url(../img/common/ico_tel_footer.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      transform: translateY(1px);
      margin-right: 6px;
    }
  }

  &_address {
    font-style: normal;
    display: block;
    font-size: 1.3rem;
    line-height: calc(22 / 13);
    margin-top: 15px;

    @include mixin.sp {
      margin-top: 20px;
    }
  }

  &_copyright {
    display: block;
    font-size: 1.2rem;
    line-height: 1;
    color: color.$site-write;
    margin: 0;

    @include mixin.pc {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      position: fixed;
      z-index: 96;
      right: 39px;
      bottom: 43px;
    }

    @include mixin.sp {
      color: color.$white;
      margin-top: 40px;
    }

    & > small {
      & > span {
        display: inline-block;
        transform: rotate(90deg);
      }
    }
  }
}
